import React, { useState, useEffect, useCallback } from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getGeoAddress } from "../utils/map";
import { AddressForm } from "./AddressForm";
import { Map, Marker } from "./Map";
import { useUpdateOrderAddressMutation } from "../api-services/order";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => {
  return {
    formContainer: {
      margin: "0 20px 20px",
    },
    mapContainer: {
      margin: "0 20px 20px",
    },
    submitBtnContainer: {
      margin: "10px 20px 20px 20px",
      textAlign: "right",
    },
    submitBtn: {
      height: "40px",
    },
    [theme.breakpoints.down("md")]: {
      bodyContainer: {
        display: "block",
      },
      mapContainer: {
        marginTop: "30px",
      },
    },
    [theme.breakpoints.up("md")]: {
      bodyContainer: {
        display: "flex",
      },
      formContainer: {
        width: "50%",
      },
      mapContainer: {
        width: "50%",
      },
    },
  };
});

const defaultFormData = {
  formattedAddress: "",
  place: "",
  locality: "",
  subLocality: "",
  city: "",
  state: "",
  pincode: "",
  countryCode: "",
};

const AppBody = (props) => {
  const [latLng, setLatLng] = useState();
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState();
  const [snackBar, setOpenSnackBar] = useState();
  const [formData, setFormData] = useState(defaultFormData);
  const { clientId, orderId } = useParams();
  const [loading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  const [
    updateOrderAddress,
    { isLoading: isUploading, data, error, isError, isSuccess },
  ] = useUpdateOrderAddressMutation();

  const setLatLngData = useCallback(
    async (lat, lng, placeId) => {
      if (lat && lng) {
        const {
          formattedAddress,
          place,
          locality,
          subLocality,
          city,
          state,
          countryCode,
          pincode,
        } = await getGeoAddress(lat, lng, placeId);

        setFormData({
          formattedAddress,
          place,
          locality,
          subLocality,
          city,
          state,
          countryCode,
          pincode,
        });

        setLatLng({ lat, lng });
        map.setCenter({ lat, lng });
        map.setZoom(15);
      } else {
        setLatLng();
        marker.setMap(null);
      }
    },
    [map, marker, setFormData, setLatLng]
  );

  const resetForm = useCallback(() => {
    setFormData(defaultFormData);
    setLatLngData();
  }, [setFormData, setLatLngData]);

  useEffect(() => {
    if (!isError) return;
    setOpenSnackBar({
      isSuccess: false,
      message: error?.data?.messageCode
        ? t(`statusCode.${error?.data?.messageCode}`)
        : t(`statusCode.1003`),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      setOpenSnackBar({
        isSuccess: true,
        message: "Order address updated successfully!!!",
      });
      resetForm();
    }
  }, [data, isSuccess, resetForm]);

  const handleSubmit = () => {
    const payload = {
      address: formData,
      token: token,
      ...(latLng && { latLng: latLng }),
    };
    updateOrderAddress({ clientId, orderId, payload });
  };

  const classes = useStyles();

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBar ? true : false}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar()}
      >
        <Alert
          onClose={() => setOpenSnackBar()}
          severity={snackBar?.isSuccess ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {snackBar?.message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || isUploading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={classes.bodyContainer}>
        <Box className={classes.formContainer}>
          <AddressForm
            setFormData={setFormData}
            formData={formData}
            setLatLngData={setLatLngData}
            resetForm={resetForm}
            setIsLoading={setIsLoading}
            setOpenSnackBar={setOpenSnackBar}
          />
        </Box>
        <Box className={classes.mapContainer}>
          <Map
            latLng={latLng}
            setLatLng={setLatLng}
            setMap={setMap}
            map={map}
          />
          <Marker
            latLng={latLng}
            setLatLngData={setLatLngData}
            map={map}
            marker={marker}
            setMarker={setMarker}
          />
        </Box>
      </Box>
      <Box className={classes.submitBtnContainer}>
        <Button
          className={classes.submitBtn}
          variant="contained"
          onClick={handleSubmit}
          disabled={formData?.formattedAddress?.length <= 0}
        >
          {t("submit")}
        </Button>
      </Box>
    </Box>
  );
};

export default AppBody;
