//@flow

import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { PageNotFound } from "./Common";
import AppContainer from "./AppContainer";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/clientId/:clientId/deliveryId/:orderId/updateAddress"
        element={<AppContainer />}
      />
      <Route exact path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
