import React, { useCallback, useEffect } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import i18n, { languageOptions } from "../../i18n";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
const logoUrl = require("../../img/logo.png");

const useStyles = makeStyles((theme) => {
  return {
    languageToggleContainer: {
      cursor: "pointer",
      width: "60px",
    },
    menuItemlabel: {
      padding: "12px",
      fontSize: "14px",
      cursor: "pointer",
    },
    menuItemlabelSelected: {
      padding: "12px",
      fontSize: "14px",
      cursor: "pointer",
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
    flag: {
      width: "12px",
      marginRight: "8px",
    },
  };
});

const AppHeader = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedLng, setSelectedLng] = React.useState(i18n.language || "ar");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(selectedLng).then(() => {
      document.title = t("websiteTitle");
      handleClose();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLng, handleClose]);

  const handleChange = useCallback(
    (value) => () => {
      setSelectedLng(value);
      handleClose();
    },
    [handleClose]
  );

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <img src={logoUrl} alt="Masdar" width={"130px"} height={"130px"} />
        <Box>
          <Box
            className={classes.languageToggleContainer}
            onClick={handleClick}
          >
            <Box display="flex" width="100%" justifyContent="center">
              {Boolean(anchorEl) ? <CloseIcon /> : <LanguageIcon />}
            </Box>
            <Box display="flex" width="100%" justifyContent="center">
              {Boolean(anchorEl) ? t("close") : t("language")}
            </Box>
          </Box>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "160px",
            marginTop: "5px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 4px rgba(17, 19, 24, 0.16)",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <Box margin={"4px 16px 8px"}>
          <Box variant="title4">{t("language")}</Box>
        </Box>
        <Box overflow="auto" maxHeight="190px">
          {languageOptions.map((item) => (
            <MenuItem key={item.key} onClick={handleChange(item.key)}>
              <Box
                style={{ padding: "4px 0" }}
                className={
                  item.key === selectedLng
                    ? classes.menuItemlabelSelected
                    : classes.menuItemlabel
                }
              >
                <Box display="flex" alignItems="center">
                  {t(`languages.${item.value}`)}
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Box>
  );
};

export default AppHeader;
