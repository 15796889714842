export const CONFIG = {
  TIMEZONE: "Asia/Riyadh",
  LOCUS: {
    LILY_API: {
      BASE_URL_PRE_PROD: process.env.REACT_APP_LOCUS_MASDAR_LILY_PRE_PROD,
      BASE_URL_PROD: process.env.REACT_APP_LOCUS_MASDAR_LILY_PROD,
    },
    CLIENT_ID: {
      PRE_PROD: "masdar-devo",
      PROD: "masdar",
    },
  },
  MAP: {
    COUNTRY: ["SA"],
    API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    DEFAULT_ZOOM: 5,
    CENTER_LAT_LNG: { lat: 23.8859, lng: 45.0792 },
  },
};
