import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const SKUTable = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  const totalQuantity = useMemo(
    () => data?.map(({ quantity }) => quantity).reduce((sum, i) => sum + i, 0),
    [data]
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell style={{ color: "#00436a" }}>{t("skuId")}</TableCell>
            <TableCell style={{ color: "#00436a" }}>{t("skuName")}</TableCell>
            <TableCell style={{ color: "#00436a" }}>{t("quantity")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(data) && data?.length > 0 ? (
            <React.Fragment>
              {data?.map((skuItem) => (
                <TableRow key={skuItem.id}>
                  <TableCell>{skuItem.id}</TableCell>
                  <TableCell>{skuItem.name}</TableCell>
                  <TableCell>{skuItem.quantity}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2}>{t("total")}</TableCell>
                <TableCell>{totalQuantity}</TableCell>
              </TableRow>
            </React.Fragment>
          ) : (
            <TableRow>
              <TableCell>{t("noSkuFound")}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SKUTable;
