export const getAddressComponentList = (geoAddress) => {
  let place = "";
  let locality = "";
  let subLocality = "";
  let city = "";
  let state = "";
  let countryCode = "";
  let pincode = "";

  for (const component of geoAddress) {
    for (const componentType of component.types) {
      let switchCaseMatched = true;

      switch (componentType) {
        case "street_number": {
          place = `${component.long_name} ${place}`;
          break;
        }
        case "street_address": {
          place += `${place ? ", " : ""}${component.long_name}`;
          break;
        }
        case "route": {
          place += `${place ? ", " : ""}${component.long_name}`;
          break;
        }
        case "neighborhood": {
          place += `${place ? ", " : ""}${component.long_name}`;
          break;
        }
        case "premise": {
          place += `${place ? ", " : ""}${component.long_name}`;
          break;
        }
        case "landmark": {
          place += `${place ? ", " : ""}${component.long_name}`;
          break;
        }
        case "sublocality": {
          subLocality += `${subLocality ? ", " : ""}${component.long_name}`;
          break;
        }
        case "locality": {
          locality += `${component.long_name}`;
          break;
        }
        case "postal_code": {
          pincode = `${component.long_name}${pincode}`;
          break;
        }
        case "administrative_area_level_2":
          city = component.long_name;
          break;
        case "administrative_area_level_1": {
          state = component.long_name;
          break;
        }
        case "country":
          countryCode = component.short_name;
          break;
        default:
          switchCaseMatched = false;
          break;
      }

      if (switchCaseMatched) break;
      else continue;
    }
  }

  return {
    place,
    locality,
    subLocality,
    city,
    state,
    countryCode,
    pincode,
  };
};

export const getGeoAddress = async (lat, lng, placeId) => {
  if (!window?.google?.maps) return {};
  if (!lat && !lng && !placeId) return {};

  return new Promise((resolve) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        ...(placeId ? { placeId } : { latLng: { lat, lng } }),
      },
      (responses, status) => {
        const geoAddress = responses?.[0]?.address_components;
        if (status === "OK" && geoAddress) {
          const { place, locality, subLocality, city, state, countryCode, pincode } =
            getAddressComponentList(geoAddress);

          resolve({
            formattedAddress: responses?.[0]?.formatted_address,
            place,
            locality,
            subLocality,
            city,
            state,
            countryCode,
            pincode,
          });
        } else resolve({});
      }
    );
  });
};
