import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const languageOptions = [
  {
    key: "en",
    value: "en",
  },
  { key: "ar", value: "ar" },
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    language: "ar",
    fallbackLng: "ar",
    react: {
      useSuspense: true,
    },
  });

export default i18n;
export { i18n, languageOptions };
