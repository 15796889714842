import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const { CONFIG } = require("../config");

export const baseQuery = async (args, WebApi, extraOptions) => {
  const { clientId } = args;
  let baseUrl;

  if (clientId === CONFIG.LOCUS.CLIENT_ID.PROD)
    baseUrl = CONFIG.LOCUS.LILY_API.BASE_URL_PROD;
  else if (clientId === CONFIG.LOCUS.CLIENT_ID.PRE_PROD)
    baseUrl = CONFIG.LOCUS.LILY_API.BASE_URL_PRE_PROD;

  const rawBaseQuery = fetchBaseQuery({
    baseUrl: `${baseUrl}/locus/clientId/${clientId}/order`,
  });
  return rawBaseQuery(args, WebApi, extraOptions);
};

export const api = createApi({
  baseQuery,
  endpoints: (builder) => ({
    getOrderDetails: builder.mutation({
      query: ({ clientId, orderId, token }) => {
        return {
          url: `${orderId}?token=${token}`,
          method: "GET",
          clientId,
        };
      },
    }),
    updateOrderAddress: builder.mutation({
      query: ({ clientId, orderId, payload }) => {
        return {
          url: `${orderId}/update-address`,
          method: "POST",
          body: payload,
          clientId,
        };
      },
    }),
  }),
});

export const { useUpdateOrderAddressMutation, useGetOrderDetailsMutation } =
  api;
