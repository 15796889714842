import moment from "moment-timezone";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./jsx/component/App";
import { CONFIG } from "./jsx/config";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

moment.defaultFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
moment.tz.setDefault(CONFIG.TIMEZONE);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
