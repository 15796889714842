import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment-timezone";
import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useGetOrderDetailsMutation } from "../../api-services/order";
import { CONFIG } from "../../config";
import { getAddressComponentList } from "../../utils/map";
import SKUTable from "./SKUTable";
const locationIconUrl = require("../../../img/my_location.png");

let autoComplete;

const LocationIcon = () => <img alt="" src={locationIconUrl} width="28px" />;

const useStyles = makeStyles(() => {
  return {
    accordionContainer: {
      boxShadow: "none !important",
    },
    accordionSummary: {
      padding: "0 !important",
      margin: "0 !important",
    },
    accordionIcon: {
      color: "#00457C !important",
    },
    accordionDetails: {
      paddingRight: "0 !important",
    },
  };
});

const AddressForm = (props) => {
  const {
    setLatLngData,
    resetForm,
    setFormData,
    formData,
    setIsLoading,
    setOpenSnackBar,
  } = props;
  const { clientId, orderId } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  const [getOrderDetails, { isLoading, data, error, isError }] =
    useGetOrderDetailsMutation();

  useEffect(() => {
    getOrderDetails({ clientId, orderId, token });
  }, [getOrderDetails, clientId, orderId, token]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [setIsLoading, isLoading]);

  useEffect(() => {
    isError &&
      setOpenSnackBar({
        isSuccess: false,
        message: error?.data?.messageCode
          ? t(`statusCode.${error?.data?.messageCode}`)
          : t(`statusCode.1003`),
      });
  }, [isError, error, setOpenSnackBar, t]);

  const fillInAddress = useCallback(() => {
    const geoAddress = autoComplete.getPlace();

    const latLng = geoAddress?.geometry?.location;
    setLatLngData(latLng?.lat(), latLng?.lng());

    const { place, locality, subLocality, city, state, countryCode, pincode } =
      getAddressComponentList(geoAddress.address_components);

    setFormData({
      formattedAddress: geoAddress.formatted_address,
      place,
      locality,
      subLocality,
      city,
      state,
      countryCode,
      pincode,
    });
  }, [setLatLngData, setFormData]);

  useEffect(() => {
    if (!window.google) return;
    const addressComponent = document.querySelector("#format-address");

    autoComplete = new window.google.maps.places.Autocomplete(
      addressComponent,
      {
        componentRestrictions: { country: CONFIG.MAP.COUNTRY },
        fields: ["address_components", "geometry", "formatted_address"],
        types: [],
      }
    );

    autoComplete.addListener("place_changed", fillInAddress);
  }, [fillInAddress]);

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const successFunction = (position) => {
    setIsLoading(false);
    setLatLngData(position.coords.latitude, position.coords.longitude);
  };

  const errorFunction = () => {
    setIsLoading(false);
    alert("Geocoder failed");
  };

  const onChangeForm = (key, value) => {
    if (key === "formattedAddress" && value === "") resetForm();
    else setFormData({ ...formData, [key]: value });
  };

  return (
    <Box>
      <Box marginBottom={3}>
        <Accordion
          expanded={open}
          onChange={() => setOpen(!open)}
          classes={{ root: classes.accordionContainer }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon classes={{ root: classes.accordionIcon }} />
            }
            classes={{ root: classes.accordionSummary }}
          >
            <Box
              fontWeight={600}
              fontSize={24}
              fontStyle={{ color: "#00457C" }}
            >
              {t("deliveryId")}: {orderId}
            </Box>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetails }}>
            {!data?.data?.length > 0 && <Box>{t("noOrdersFound")}</Box>}
            {data?.data.map((item) => (
              <Box key={item.id} marginBottom={3}>
                <Box
                  marginBottom={2}
                  fontWeight={600}
                  fontSize={16}
                  display="flex"
                  justifyContent={"space-between"}
                >
                  <Box fontStyle={{ color: "#00436a" }}>{t("orderId")}:</Box>
                  <Box>{item.id}</Box>
                </Box>
                <Box
                  marginBottom={2}
                  fontWeight={600}
                  fontSize={16}
                  display="flex"
                  justifyContent={"space-between"}
                >
                  <Box paddingRight={1} fontStyle={{ color: "#00436a" }}>
                    {t("deliveryDate")}:
                  </Box>
                  <Box>{moment(item.slot?.start).format("DD/MM/YYYY")}</Box>
                </Box>
                <Box
                  marginBottom={2}
                  fontWeight={600}
                  fontSize={16}
                  display="flex"
                  justifyContent={"space-between"}
                >
                  <Box paddingRight={1} fontStyle={{ color: "#00436a" }}>
                    {t("deliverySlot")}:
                  </Box>
                  <Box>
                    {moment(item.slot?.start).format("hh:mm a")} -
                    {moment(item.slot?.end).format("hh:mm a")}
                  </Box>
                </Box>
                <SKUTable data={item.skuItems} />
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box marginBottom={3}>
        <TextField
          id="format-address"
          label={t("deliveryTo")}
          placeholder={t("searchForAddress")}
          required
          type="search"
          fullWidth
          size="small"
          autoComplete="off"
          value={formData.formattedAddress}
          onChange={(e) => onChangeForm("formattedAddress", e.target.value)}
        />
      </Box>
      <Box marginBottom={6} display="flex">
        <Box marginRight={3} marginY={1.5}>
          {t("or")}
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={handleGetCurrentLocation}
            startIcon={<LocationIcon />}
          >
            {t("useCurrentLocation")}
          </Button>
        </Box>
      </Box>
      <Box>
        <TextField
          id="secondary-address"
          label={t("addressTitle")}
          multiline
          size="small"
          rows={3}
          fullWidth
          value={formData.place}
          onChange={(e) => onChangeForm("place", e.target.value)}
        />
      </Box>
    </Box>
  );
};

export default AddressForm;
