import { Box } from "@mui/material";
import React, { useEffect } from "react";

const Marker = (props) => {
  const { animate, marker, map, setMarker, latLng, setLatLngData } = props;

  useEffect(() => {
    if (!map) return;
    if (!marker) setMarker(new window.google.maps.Marker());

    return () => marker && marker.setMap(null);
  }, [marker, map, setMarker]);

  useEffect(() => {
    if (!map) return;

    ["click"].forEach((eventName) => window.google.maps.event.clearListeners(map, eventName));
    map.addListener("click", (e) => {
      setLatLngData(e.latLng.lat(), e.latLng.lng(), e.placeId);
    });
  }, [map, setLatLngData]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        map,
        position: latLng,
        draggable: true,
      });
      if (animate) marker.setAnimation(window.google.maps.Animation[animate]);
      else marker.setAnimation(null);
      //Listen events

      ["mouseup"].forEach((eventName) =>
        window.google.maps.event.clearListeners(marker, eventName)
      );
      marker.addListener("mouseup", (e) => {
        setLatLngData(e.latLng.lat(), e.latLng.lng());
      });
    }
  }, [marker, animate, setLatLngData, latLng, map]);

  return <Box />;
};

Marker.displayName = "Marker";
export default Marker;
