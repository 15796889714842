import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => {
  return {
    pageContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      lineHeight: "1.4",
      margin: "auto",
      paddingTop: "60px",
      position: "relative",
      color: theme.palette.primary.main,
    },
    errorCode: {
      fontSize: "8rem",
      fontWeight: 600,
    },
    errorTitle: {
      fontSize: "2rem",
      lineHeight: 1.4,
      marginBottom: "10px",
    },
    errorMsg: {},
  };
});

const PageNotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.errorCode}>404</Box>
      <Box className={classes.errorTitle}>{t("400Title")}</Box>
      <Box className={classes.errorMsg}>{t("400Desc")}</Box>
    </Box>
  );
};

export default PageNotFound;
